<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
  <li>Identifying Autism </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Identifying Autism</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
<div class="mycontent">
   <div class="con2">
    
    
    <p>
      Developmental, behavioral, and learning disabilities are on the rise. Though the exact cause of autism is still not known, intensive early intervention can help in improving the quality of life of children at risk and their families.
    </p>
    <p>
      As there are no blood tests or other medical tests, diagnosing autism at the early age is difficult. The diagnosis of autism can be given by the child’s pediatrician or developmental pediatrician by using screening tools and it is based on parent’s interview, child’s developmental history, speech and language assessment, educational and psychological testing, and observation of the child’s behavior. An experienced professional can diagnose autism in toddlers as young as 18 to 24 months of age.
    </p>
    <p>
      Recognizing the red flags can help in getting early diagnosis and planning intervention program for the child. Early intervention can help in building strengths in children to teach new skills, improve behaviors, and remediate areas of weakness. This can have long term impact which will help individuals with autism to lead productive, inclusive, and fulfilling lives.  
    </p>
    <p>
      Close observation of a child’s overall developmental milestones by the parents or caregivers can help in understanding red flags at a nascent stage. The following “red flags” can help in indicating that your child may have autism spectrum disorder.
    </p>
    
    <mat-expansion-panel (opened)="panelOpenState = true"
    (closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
  Impairment in Social interaction
 </div>
</mat-panel-title>
</mat-expansion-panel-header>
    <!-- <div class="mycontent1"> -->
      <div class="row">
      <div class="column" >
    <ul>
      <li>Avoid gazing or showing unusual eye contact</li>
      <li>Prefer to be alone</li>
      <li>Lack of response to name</li>
      <li>Lack of warm, joyful expressions</li>
      <li>Lack of sharing interest or enjoyment</li>
      <li>May show no interest in other children playing</li>
      <li>May not notice when parent leaves or returns from work</li>
      <li>May show no interest in Peek-a-Boo or other interactive games</li>
      <li>May strongly resist being held, hugged, or kissed by parents</li>
      <li>
        May not raise arms to be picked up from crib when someone reaches out to
        pick him up
      </li>
    </ul>
    
   </div>
      <div class="column1" >
        <img src="assets/images/content/mydown-img/pranav2.jpeg"  >
        
      </div>
    </div>
  </mat-expansion-panel>
    <br />
    
   
    <mat-expansion-panel (opened)="panelOpenState = true"
    (closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
  Impairment in Communication
 </div>
</mat-panel-title>
</mat-expansion-panel-header>
    <!-- <div class="mycontent1"> -->
      <div class="row">
        <div class="column2" >
     
          <img src="assets/images/content/mydown-img/image 5 (1).jpg">
        </div>
     
      <div class="column3" >
        <ul>
          <li>
            Lack of showing gestures such as pointing to ask for something
          </li>
          <li>Pulls parents hand to ask for the things</li>
          <li>No smile in response to parents face or smile</li>
          <li>
            Lack of non-verbal communication such as waving bye or showing
            objects to others
          </li>
          <li>
            Show delay or lack of language development or loss of early acquired
            language
          </li>
          <li>Unaware of environment</li>
          <li>Avoids eye-contact</li>
          <li>Echo words or phrases</li>
          <li>Have difficulty in initiating and sustaining a conversation</li>
        </ul>
      </div>
    </div>
      
    </mat-expansion-panel>
    <br />

    <mat-expansion-panel (opened)="panelOpenState = true"
    (closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
  Repetitive Behaviors & Restricted Interests
 </div>
</mat-panel-title>
</mat-expansion-panel-header>
    <!-- <div class="mycontent1"> -->
      <div class="row">
        <div class="column" >
    
    <ul>
      <li>
        Repetitive movements with toys such as lining up their cars, trains, or
        blocks
      </li>
      <li>
        Enjoy rotating or spinning objects like plastic jars, bottle lids, bowls
      </li>
      <li>
        They like to play only with parts of objects like knobs of machines,
        switches of switchboard, wheels of the toy cars
      </li>
      <li>
        Repetitive body movements such as hands flapping or rocking body or
        walking on toes
      </li>
      <li>Rigidity in following daily routines</li>
      <li>Gets upset by change in routes or timetable</li>
      <li>
        Has unusual reactions to the way things sound, smell, taste, look, or
        feel
      </li>
      <li>
        Display good rote memory for nursery rhymes, commercial jingles,
        irrelevant facts.
      </li>
    </ul>
    <div class="mynote">
      <b>Note:</b>
      These repetitive activities can prolong for extended hours throughout the
      day and can be completely oblivion to changes in surrounding environment.
    </div>
  </div>
  <div class="column1" >
    <img src="assets/images/content/mydown-img/image 6 (1).jpg"  >
    
  </div>

    <br />
        
  </div>
</mat-expansion-panel>
   </div>