import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finan-planning',
  templateUrl: './finan-planning.component.html',
  styleUrls: ['./finan-planning.component.css']
})
export class FinanPlanningComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
