<section class="bg-half  d-table w-100" id="home">

  

  <!-- <nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div>
    </div>
</nav> -->


<app-blog-header></app-blog-header>





  <div class="row" style="display: flex; justify-content: center">
    <div class="col-xs-12 col-md-6">
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" style="text-align: center" class="loading-text">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
        *ngIf="!isLoading"
      >
        <div class="form-group">
          <label for="email">E-Mail</label>

          <input
            type="email"
            id="email"
            class="form-control"
            ngModel
            name="email"
            required
            email
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            id="password"
            class="form-control"
            ngModel
            name="password"
            required
            minlength="6"
          />
        </div>
        <br />
        <div class="form-group">
          <button
            class="btn btn-primary"
            type="submit"
            [disabled]="!authForm.valid"
          >
            {{ isLoginMode ? "Login" : "Sign Up" }}
          </button>
          &nbsp;
          <button
            class="btn btn-primary"
            (click)="onSwitchMode()"
            type="button"
          >
            Switch to {{ isLoginMode ? "Sign Up" : "Login" }}
          </button>
          <a
            [routerLink]="['/reset-password']"
            class="btn btn-link"
            style="text-align: right"
            >Forget Password?</a
          >
        </div>

        <div
          class="form-group"
          style="text-align: center; display: flex; justify-content: center"
        >
          <button
            type="button"
            class="btn btn-primary"
            style="background-color: #2f55d4"
            (click)="tryGoogleLogin()"
          >
            <i class="fa fa-google" aria-hidden="true"></i>
            Login with Google
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
