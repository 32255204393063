import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-behave-management',
  templateUrl: './behave-management.component.html',
  styleUrls: ['./behave-management.component.css']
})
export class BehaveManagementComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
