<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li>Autism Spectrum Disorder </li>
</ul>


<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
    <div class="hero_innercontainer" data-uw-styling-context="true">
      <div class="hero_content" data-uw-styling-context="true">
        <h2 class="hero_title" data-uw-styling-context="true">
          <span data-uw-styling-context="true">Autism Spectrum Disorder (ASD)</span>
        </h2>
      </div>
    </div>
  </div>
</div>

<div class="mycontent">

  <!-- <div class="row">
      <div class="column" > -->
  <p>
    Autism is a developmental disorder that typically can be observed in the
    first three years of a child's life. It can last throughout a person's
    life, although symptoms may improve over time. Autism appears as a broad
    range of differences in development in three main areas referred to as the triad of impairments:
  </p>
  <ul>
    <li>Communication (verbal and non-verbal)/Social interactions</li>
    <li>Repetitive behaviors and restricted interests in activities.</li>
    <br />

  </ul>

  <p>
    In 2013, the American Psychiatric Association (fifth edition of its
    Diagnostic and Statistical Manual of Mental Disorders i.e <a routerLink="dsm">DSM-5</a>.) merged
    four distinct autism diagnoses into one umbrella diagnosis:
  </p>

  <ol>
    <li>Autistic disorder</li>

    <li>Childhood disintegrative disorder,</li>

    <li>
      Pervasive developmental disorder - Not otherwise specified (PDD-NOS)
    </li>

    <li>Asperger Syndrome</li>
  </ol>

  <br>
  <p>
    According to the Centers for Disease Control, autism affects an estimated
    1 in 44 children in the United States according to 2018 data. Autism is
    present in all racial, ethnic, geographical, socioeconomic groups, and
    family income, lifestyle, educational levels of parents do not affect the
    chance of autism's occurrence.
  </p>
  <!-- </div> -->
  <!-- <div class="column1" >
    
     <img src="assets/images/content/aut-img/venn2.svg"  >
  </div> -->
</div>

<br><br>

<div class="head2">
  <h3> Characteristics of Autism</h3>
</div>

<div class="row">
  <div class="column">

    <p>
      Autism is a “spectrum” disorder because of the wide variation in the
      type and severity of symptoms people with autism experience. Each person
      with autism has different strengths, challenges and therefore the ways
      of their learning, thinking and problem-solving can range from highly
      skilled to severely challenged. Some people with autism may require
      significant support in their daily lives, while others may need less
      support and, in some cases, they can live entirely independently.
    </p>
  </div>
  <div class="column1">
    <img src="assets/images/content/underautism-img/Picture1.png" alt="" />
  </div>
</div>
<br><br>
<div class="row">
  <div class="column2">
    <img src="assets/images/content/underautism-img/Picture2.png" alt="" />
  </div>
  <div class="column3">

    <p> Autism results in qualitative impairments. It means different skills
      develop at different pace in people with autism.
      E.g., If a child is at the biological age of 8 years, his overall development would be in
      congruence to that of an 8-year-old child. In autism however, an
      8-year-old child may have speech development like that of a 3-year,
      gross motor skills developed like 2-year, fine motor skills of 9, and
      self-help skills of 5-year child as depicted through given graph.</p>
    <p>
      Due to these variations in skill development, no two people having autism
      spectrum look, behave, or respond in similar manner.Hence, educational programs for the individual with autism
      must be based on <a routerLink="funct-assest">Functional assessment.</a>
    </p>
  </div>

</div>

<br />
<div class="mycontent">
<ul>

  <li>
    Like all individuals, each person with autism has a unique personality
    and combination of characteristics. Thus, two children, both with the
    same diagnosis, can act very differently from one another and can have
    varying skills; but they can learn using the same techniques.
  </li>
  <li>
    Development of <a routerLink="neuro-theories">neurocognitive theories</a> (Theory of mind, Theory of Executive
    dysfunction, and theory of central coherence) has helped in explaining etiology of autism.
  </li>

  <li>Difficulty in understanding others minds I.e., <a routerLink="neuro-theories"> theory of mind </a>is a core
    cognitive deficit underlying the social and communication difficulties in autism. </li>


  <li> Individuals with autism have deficits in <a routerLink="neuro-theories">Executive Functioning</a> particularly in
    planning, mental flexibility, inhibition, and self-monitoring. Hence, they face difficulties in doing daily
    activities independently. E.g., a person with autism might need help from the parents or caregivers in planning
    activities for the whole day or may need help in doing routine activities of daily living like eating, brushing,
    dressing up.</li>

  <li> Many of the people with autism have limited ability to understand the context or "see the bigger picture” known
    as core deficit in<a routerLink="neuro-theories"> central coherence</a>. Hence, they are observed to be providing
    attention to the details of the thing rather than understanding the whole picture contextually. E.g., The child can
    find that mother is wearing new jewelry, Bindi, saree but may not be able to relate it to the context that she is
    going to a party.
  </li>

  <li>
    People with autism may also have a secondary diagnosis of <a routerLink="sensory-disorder">Sensory
      Processing Disorder</a>. They may have difficulties related to sensitivities
    to touch, auditory input, smell, vestibular and proprioceptive inputs.
    However, children with autism have additional difficulties communicating
    with others what sensations are uncomfortable or calming, they often use
    behavior to let others know what they think about something.
    Communication difficulties can easily aggravate the severity of
    behavioral reactions to sensory input.
  </li>

  <li>
    Autism is often accompanied by medical issues such as gastrointestinal
    (GI) disorders, seizures or sleep disorders, mental health challenges
    such as anxiety, depression, and attention issues. People with autism
    may have comorbid conditions such as intellectual disability, cerebral
    palsy, down syndrome, Fragile X syndrome, Tuberous sclerosis, learning
    disability or any other physical disability.
  </li>

</ul>

</div>